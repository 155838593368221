import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { styles } from 'app/theme'
import React, { memo } from 'react'

export interface Props {
  title?: string
  image_left?: ImageProps
  description_right?: string
  description_left?: string
  image_right?: ImageProps
}

export const DoubleText = memo(function DoubleText({
  title,
  image_left,
  description_right,
  description_left,
  image_right,
}: Props) {
  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      <Wrapper>
        {image_right ? <ImageSmall variant="right" {...image_right} /> : null}

        <Left>
          {image_left ? <ImageSmall variant="left" {...image_left} /> : null}
          {description_left ? (
            <FadeInUp>
              <Description
                variant="left"
                dangerouslySetInnerHTML={{ __html: description_left }}
              />
            </FadeInUp>
          ) : null}
        </Left>
        <Right>
          {description_right ? (
            <FadeInUp>
              <Description
                variant="right"
                dangerouslySetInnerHTML={{ __html: description_right }}
              />
            </FadeInUp>
          ) : null}
          {image_right ? <ImageSmall variant="right" {...image_right} /> : null}
        </Right>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 8.938vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.25rem 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;

  @media (max-width: 992px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-top: 7.1875rem;
  margin-left: 8.9375rem;

  @media (max-width: 1600px) {
    margin-left: 8.938vw;
  }

  @media (max-width: 1199px) {
    margin-left: 6.938vw;
  }

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
    margin-left: 0;
    margin-top: 0rem;

    & > picture {
      display: none;
    }
  }

  @media (max-width: 550px) {
    display: block;
  }
`

const ImageSmall = styled(Image)<ImageSmallProps>`
  position: relative;
  display: block;
  max-width: 33.125rem;
  margin-top: 7.1875rem;
  aspect-ratio: 530/600;

  @media (max-width: 1440px) {
    margin-top: 6.25rem;
  }

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }

  @media (max-width: 992px) {
    width: 100%;
    margin: 0 auto;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'right':
        return css`
          margin-top: 5.1875rem;
        `
    }
  }}
`

const Title = styled.h2`
  ${styles.h2}
  max-width: 28rem;

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
    margin: 0 auto 1.8rem;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }
`

const Description = styled.div<DescriptionPros>`
  ${styles.description}
  max-width: 26.75rem;
  margin-right: 6.25rem;

  p {
    margin-bottom: 1.8rem;
  }

  @media (max-width: 1600px) {
    margin-right: 6.25vw;
  }

  @media (max-width: 1440px) {
    margin-right: 0;
  }

  @media (max-width: 992px) {
    max-width: 49.0625rem;
    text-align: center;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    text-align: left;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'left':
        return css`
          margin-top: 8rem;

          @media (max-width: 992px) {
            margin-top: 1.8rem;
          }
        `
    }
  }}
`

interface ImageSmallProps {
  variant?: 'left' | 'right'
}
interface DescriptionPros {
  variant?: 'left' | 'right'
}
